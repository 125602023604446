<template>
  <div class="body">
    <div class="addtable">
      <van-nav-bar left-text="返回" left-arrow @click-left="onpage">
        <template #right>
          <van-button class="btn" type="warning" @click="complete('finish')">完成评估</van-button>
        </template>
      </van-nav-bar>
      <div class="from">
        <div class="mycard">
          <van-button class="pingubtn" type="danger">{{ name + " - " + mileage_name }}</van-button>
          <div class="text">{{ title }}</div>
          <div class="view-btns">
            <div class="btn" :class="{active: viewStatus === 1}" @click="viewStatus = 1">查看教具<van-icon name="arrow-down" /></div>
            <div class="btn" :class="{active: viewStatus === 2}" @click="viewStatus = 2">查看评分标准<van-icon name="arrow-down" /></div>
          </div>
          <div class="view-content" v-if="viewStatus">
            <template v-if="viewStatus === 1">
              <div style="line-height: 1.5;">{{ tool || '无' }}</div>
            </template>
            <template v-if="viewStatus === 2">
              <div class="standard">
                <span class="score">0.5分</span>
                <span>{{ half_norm }}</span>
              </div>
              <div class="standard">
                <span class="score">1分</span>
                <span>{{ full_norm }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="listproject">
        <div class="item">
          <p v-if="assist_list.length >= 1">辅助<span v-if="elect_assist === 0">选填</span></p>
          <ul>
            <li :class="{ redfont: fzcss === index }" v-for="(item, index) in assist_list" :key="index" @click="addfz(item, index)">{{ item.option_name }}</li>
          </ul>
        </div>
        <div class="item">
          <p v-if="envir_list.length >= 1">环境<span v-if="elect_envir === 0">选填</span></p>
          <ul>
            <li :class="{ redfont: hjcss === index }" v-for="(item, index) in envir_list" :key="index" @click="addhj(item, index)">{{ item.option_name }}</li>
          </ul>
        </div>

        <div class="item">
          <p v-if="place_list.length >= 1">场所<span v-if="elect_place === 0">选填</span></p>
          <ul>
            <li :class="{ redfont: cscss === index }" v-for="(item, index) in place_list" :key="index" @click="addcs(item, index)">{{ item.option_name }}</li>
          </ul>
        </div>
        <div class="item">
          <p v-if="noun_list.length >= 1">词性<span v-if="elect_noun === 0">选填</span></p>
          <ul>
            <li :class="{ redfont: cxcss === index }" v-for="(item, index) in noun_list" :key="index" @click="addcx(item, index)">{{ item.option_name }}</li>
          </ul>
        </div>
        <div class="item">
          <p v-if="item_list.length >= 1">项目<span v-if="elect_item === 0">选填</span></p>
          <ul>
            <li :class="{ redfont: xmcss === index }" v-for="(item, index) in item_list" :key="index" @click="addxm(item, index)">{{ item.option_name }}</li>
          </ul>
        </div>
        <div class="input">
          <p></p>
          <div class="con">
            <van-cell-group>
              <van-field v-model="value" maxlength="8" placeholder="请输入标签没有的项目" />
            </van-cell-group>
            <van-button type="danger" @click="addproject">添加</van-button>
          </div>
        </div>
      </div>
      <div class="scorebtn" @click="scoreshow">
        <div class="box"></div>
      </div>
      <div class="fixedcard">
        <div class="buttongroup preTopic" v-if="this.topic_sort > 1" @click="ona">
          <div style="border: 1px solid #ff8a88">上一题</div>
        </div>
        <div class="buttongroup preTopic" v-else>
          <div></div>
        </div>
        <div class="item" @click="opentable">
          <span class="iconfont icon-ICON myicon"></span>
          <span>项目记录(总数{{ tableData.length }}个)</span>
        </div>
        <div class="score">
          <span :class="{ active: scorerecord.fullscore === 0, active1: zero }" @click="scoreclick(0)">0分</span>
          <span :class="{ active: scorerecord.fullscore == 0.5, active1: zerofive }" @click="scoreclick(0.5)">0.5分</span>
          <span :class="{ active: scorerecord.fullscore == 1, active1: one }" @click="scoreclick(1)">1分</span>
        </div>
        <div class="buttongroup">
          <div v-if="text === '手动'" style="background: #eea837; color: #fff" @click="manual('手动')">手动打分</div>
          <div v-if="text === '自动'" style="background: #eea837; color: #fff" @click="manual('自动')">恢复自动</div>
          <div style="border: 1px solid #ff8a88" @click="next">下一题</div>
        </div>
      </div>
      <!-- 分数弹框 -->
      <van-popup class="scorebox" v-model="show">
        <div class="calculatescore" v-for="(item, index) in scorearr" :key="index">
          <p :class="{disabled: item.toprecord_id == 0 && +item.score == 1}" @click="backSee(item.topic_sort, item.toprecord_id)">{{ item.name }}</p>
          <ul>
            <li :class="{ active: item.score === 0 || item.score === '0.0' }">0分</li>
            <li :class="{ active: item.score == 0.5 }">0.5分</li>
            <li :class="{ active: item.score == 1 }">1分</li>
          </ul>
        </div>
      </van-popup>
      <!-- 表格弹框 -->
      <div class="shadowbox" v-if="tableshow" @click="deltablemodel"></div>
      <div class="tablemodel" v-if="tableshow">
        <el-table :data="tableData" style="width: 100%" border max-height="500">
          <el-table-column width="80" label="序号" align="center" type="index"></el-table-column>
          <el-table-column prop="assist_name" label="辅助" align="center" show-overflow-tooltip :formatter="stateFormat" v-if="assist_list.length >= 1">
            <template slot="header">
              辅助<br /><span v-if="elect_assist === 0">(选填)</span>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.assist_name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="envir_name" label="环境" align="center" show-overflow-tooltip :formatter="stateFormat" v-if="envir_list.length >= 1">
            <template slot="header">
              环境<br /><span v-if="elect_envir === 0">(选填)</span>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.envir_name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="place_name" label="场所" align="center" show-overflow-tooltip :formatter="stateFormat" v-if="place_list.length >= 1">
            <template slot="header">
              场所<br /><span v-if="elect_place === 0">(选填)</span>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.place_name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="noun_name" label="词性" align="center" show-overflow-tooltip :formatter="stateFormat" v-if="noun_list.length >= 1">
            <template slot="header">
              词性<br /><span v-if="elect_noun === 0">(选填)</span>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.noun_name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="item_name" label="项目" align="center" show-overflow-tooltip :formatter="stateFormat" v-if="item_list.length >= 1">
            <template slot="header">
              项目<br /><span v-if="elect_item === 0">(选填)</span>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.item_name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="时间" align="center" width="160">
            <template slot-scope="scope">{{ moment(Number(scope.row.ctime * 1000)).format("MM/DD HH:mm") }}</template>
          </el-table-column>
          <el-table-column align="center" width="60">
            <template slot-scope="scope">
              <i class="el-icon-close" @click="deleteitem(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
        <i class="i"></i>
      </div>
      <van-overlay :show="overlayShow" :custom-style="{ background: 'rgba(0,0,0,0)', zIndex: '99' }" @click="overlayTip" :lock-scroll="false" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Notify, Toast, Overlay, Dialog } from "vant";
import "../../../plugin/element";
import { programadd, rightscore, chooseproject, getproject, delchoose, recordadd, topicRecord, finishAsses, nextTopic, nextContent } from "../../../api/index";
import { getuid, settopicid, setlesson_id, setltemname, getreviewid, gettopicid, getlesson_id, getltemname } from "/src/api/userdata.js";
export default {
  data() {
    return {
      moment,
      num: "",
      show: false,
      text: "手动",
      tableshow: false,
      tableData: [],
      currentIndex: "",
      item_list: [],
      envir_list: [],
      assist_list: [],
      place_list: [],
      noun_list: [],
      typeshow: "",
      title: "",
      name: "",
      mileage_name: "",
      questionid: "",
      value: "",
      usercheck: [],
      fuzhus: false,
      fzcss: "",
      hjs: false,
      hjcss: "",
      css: false,
      cscss: "",
      cxs: false,
      cxcss: "",
      xms: false,
      xmcss: "",
      obj: {},
      scorearr: [],
      scorefull: 1,
      scorehalf: 0.5,
      full_mark: {
        label: [],
        total_num: "",
      },
      half_mark: {
        label: [],
        total_num: "",
      },
      fulltype: "",
      halftype: "",
      scorerecord: {
        fullscore: "",
        fullcoppy: "",
        halfscore: "",
        halfcoppy: "",
      },
      topic_sort: 0,
      zero: false,
      zerofive: false,
      one: false,
      auto: "",
      nextarr: [],
      nextreviewid: "",
      nextmainid: "",
      nextlessonid: "",
      nextname: "",
      nextuid: "",
      questionid1: "",
      swithopen: false,
      exercisesdata: [],
      addTruefalsedata: [],
      // 本维度所有的题
      allTopic: [],
      trecord_id: "", //编辑必传—评估记录主表id
      toprecord_id: "", //编辑必传—答题记录主表id
      ans_record: "",
      overlayShow: false,
      elect_item: 1,
      elect_envir: 1,
      elect_assist: 1,
      elect_place: 1,
      elect_noun: 1,
      isend: true,
      tipText: '',
      viewStatus: 0,
      tool: '',
      full_norm: '',
      half_norm: ''
    };
  },
  created() {
    this.getlist();
  },
  components: { "van-overlay": Overlay },
  methods: {
    getlist() {
      const uid = getuid();
      const reviewid = getreviewid();
      const mainid = gettopicid();
      this.name = getltemname();
      if (this.$route.query.topic_sort) {
        let preTopicSort = this.$route.query.topic_sort;
        let not = this.$route.query.not;
        let type = this.$route.query.type;
        topicRecord(uid, preTopicSort, reviewid, mainid, type).then((res) => {
          if (res.code === 9990) {
            Notify({ type: 'danger', message: res.msg });
            this.$router.replace({
              name: 'login'
            })
          }
          if (res.code != 1) {
            return Notify({ type: 'danger', message: res.msg });
          }
          this.tableshow = true;
          let record = res.data.topic_info;
          this.toprecord_id = res.data.id;
          this.title = record.title;
          this.item_list = record.item_list;
          this.envir_list = record.envir_list;
          this.assist_list = record.assist_list;
          this.place_list = record.place_list;
          this.noun_list = record.noun_list;
          this.questionid = record.id;
          this.full_mark.label = record.full_mark.label;
          this.fulltype = record.full_mark.type;
          this.half_mark.label = record.half_mark.label;
          this.halftype = record.half_mark.type;
          this.full_mark.total_num = record.full_mark.total_num;
          this.half_mark.total_num = record.half_mark.total_num;
          this.topic_sort = record.topic_sort;
          this.mileage_name = record.mileage_name;
          this.num = record.main_id;
          this.trecord_id = res.data.trecord_id;
          this.elect_item = record.elect_item;
          this.elect_envir = record.elect_envir;
          this.elect_assist = record.elect_assist;
          this.elect_place = record.elect_place;
          this.elect_noun = record.elect_noun;
          this.scorerecord.fullscore = res.data.score;
          this.tool = record.tool;
          this.full_norm = record.full_norm;
          this.half_norm = record.half_norm;
          if (not == 1) {
            this.overlayShow = true;
          }
          getproject(uid, reviewid, this.questionid).then((res) => {
            if (res.data.list.length > 0) {
              this.tableData = res.data.list;
              const arrlength = this.checkArr(this.full_mark.label,this.tableData,this.fulltype);
              const arrlength2 = this.checkArr(this.half_mark.label,this.tableData,this.halftype);
              const num = Number(this.full_mark.total_num);
              const num2 = Number(this.half_mark.total_num);
              if (arrlength.length == num || arrlength.length > num) {
                this.scorerecord.fullscore = 1;
              } else if (arrlength2.length >= num2) {
                this.scorerecord.fullscore = 0.5;
              } else {
                this.scorerecord.fullscore = 0;
              }
            }
          });
          this.getNextMain();
        });
      } else {
        let params = {
          uid: uid,
          review_id: reviewid,
          main_id: mainid,
          is_rec: this.$route.query.is_rec,
          trec_id: this.$route.query.trec_id,
          topic_id: this.$route.query.topic_id,
        }
        nextContent(params).then((res) => {
          if (res.code === 9990) {
            Notify({ type: 'danger', message: res.msg });
            this.$router.replace({
              name: 'login'
            })
          }
          if (res.data.topic_type === "1") {
            this.title = res.data.title;
            this.item_list = res.data.item_list;
            this.envir_list = res.data.envir_list;
            this.assist_list = res.data.assist_list;
            this.place_list = res.data.place_list;
            this.noun_list = res.data.noun_list;
            this.questionid = res.data.id;
            this.full_mark.label = res.data.full_mark.label;
            this.fulltype = res.data.full_mark.type;
            this.half_mark.label = res.data.half_mark.label;
            this.halftype = res.data.half_mark.type;
            this.full_mark.total_num = res.data.full_mark.total_num;
            this.half_mark.total_num = res.data.half_mark.total_num;
            this.topic_sort = res.data.topic_sort;
            this.mileage_name = res.data.mileage_name;
            this.num = res.data.main_id;
            this.trecord_id = res.data.trecord_id;
            this.elect_item = res.data.elect_item;
            this.elect_envir = res.data.elect_envir;
            this.elect_assist = res.data.elect_assist;
            this.elect_place = res.data.elect_place;
            this.elect_noun = res.data.elect_noun;
            this.tool = res.data.tool;
            this.full_norm = res.data.full_norm;
            this.half_norm = res.data.half_norm;
            getproject(uid, reviewid, this.questionid).then((res) => {
              if (res.data.list.length > 0) {
                this.tableData = res.data.list;
                const arrlength = this.checkArr(this.full_mark.label,this.tableData,this.fulltype);
                const arrlength2 = this.checkArr(this.half_mark.label,this.tableData,this.halftype);
                const num = Number(this.full_mark.total_num);
                const num2 = Number(this.half_mark.total_num);
                if (arrlength.length == num || arrlength.length > num) {
                  this.scorerecord.fullscore = 1;
                } else if (arrlength2.length >= num2) {
                  this.scorerecord.fullscore = 0.5;
                } else {
                  this.scorerecord.fullscore = 0;
                }
              }
            });
          } else if (res.data.topic_type === "2") {
            this.$router.replace({
              name: "choosepicture",
              query: {
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type
              }
            });
          } else if (res.data.topic_type === "3") {
            this.$router.replace({
              name: "addTruefalse",
              query: {
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type
              }
            });
          }
          this.getNextMain();
        });
      }
    },
    async getNextMain() {
      let thisMainIndex = '';
      const uid = getuid();
      const id = getreviewid();
      let pathArr = ['must-topic/main-list', 'must-topic/skill-list', 'must-topic/feel-list', 'must-topic/rim-list']
      let params = {
        uid,
        review_id: id
      }
      const res = await this.$http.get(pathArr[this.$route.query.eva_type], { params: params });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.nextarr = res.data.list;
      for (let i = 0; i < this.nextarr.length; i++) {
        const element = this.nextarr[i].main_id;
        if (this.num == this.nextarr[this.nextarr.length - 1].main_id) {
          this.nextname = "最后一个维度了";
        }
        if (this.num == element) {
          thisMainIndex = i;
        } else if (this.num != element && this.nextarr[i].status != 1) {
          this.isend = false
        }
        if (thisMainIndex == 0 && i > thisMainIndex && this.nextarr[i].status != 1) {
          this.nextmainid = this.nextarr[i].main_id;
          this.nextname = this.nextarr[i].main_name;
          this.nextlessonid = this.nextarr[i].lesson_id
          return false
        }
        if (thisMainIndex && i > thisMainIndex && this.nextarr[i].status != 1) {
          this.nextmainid = this.nextarr[i].main_id;
          this.nextname = this.nextarr[i].main_name;
          this.nextlessonid = this.nextarr[i].lesson_id
          return false;
        } else if (thisMainIndex !== '' && i > thisMainIndex && this.nextarr[i].status == 1) {
          this.nextname = "最后一个维度了";
        }
      }
    },
    next() {
      this.complete("add");
    },
    getindex(val) {
      this.currentIndex = val;
    },
    onpage() {
      this.$router.replace({
        name: "startpingu",
        query: {
          ass_num: this.$route.query.ass_num,
          eva_type: this.$route.query.eva_type
        }
      }).then(() => {
        this.$router.go(-1)
      });
    },
    addfz(val, index) {
      this.obj.assist_name = val.option_name;
      this.fzcss = index;
      this.obj.assist_id = val.option_id;
    },
    addhj(val, index) {
      this.obj.envir_name = val.option_name;
      this.obj.envir_id = val.option_id;
      this.hjcss = index;
    },
    addcs(val, index) {
      this.obj.place_name = val.option_name;
      this.obj.place_id = val.option_id;
      this.cscss = index;
    },
    addcx(val, index) {
      this.obj.noun_name = val.option_name;
      this.obj.noun_id = val.option_id;
      this.cxcss = index;
    },
    addxm(val, index) {
      const fuzhu = this.obj.hasOwnProperty("assist_name");
      const huanjing = this.obj.hasOwnProperty("envir_name");
      const place = this.obj.hasOwnProperty("place_name");
      const speech = this.obj.hasOwnProperty("noun_name");
      if (fuzhu == false && this.elect_assist === 1) {
        return Notify({ type: 'warning', message: "请选择辅助标签" });
      }
      if (huanjing == false && this.elect_envir === 1) {
        return Notify({ type: 'warning', message: "请选择环境标签" });
      }
      if (place == false && this.elect_place === 1) {
        return Notify({ type: 'warning', message: "请选择场所标签" });
      }
      if (speech == false && this.elect_noun === 1) {
        return Notify({ type: 'warning', message: "请选择词性标签" });
      }
      this.tableData.forEach((item, index) => {
        if (item.project == val) delete this.tableData[index].project;
      });
      if (this.swithopen == false) {
        // 正常进入的维度提交
        const uid = getuid();
        const reviewid = getreviewid();
        const topic_id = this.questionid;
        this.xms = true;
        if (this.xms === true) {
          this.xmcss = index;
          const myDate = new Date();
          const nowDate =
            myDate.getMonth() +
            1 +
            "/" +
            myDate.getDate() +
            "   " +
            myDate.getHours() +
            ":" +
            myDate.getMinutes();
          const numid = this.tableData.length + 1;
          this.obj.item_name = val.option_name;
          this.obj.item_id = val.option_id;
          this.obj.time = nowDate;
          this.obj.id = numid;
          // 以下是将用户所选项传给后台
          delete this.obj.time;
          this.obj.uid = uid;
          this.obj.review_id = reviewid;
          this.obj.topic_id = this.questionid;
          this.obj.is_attach = 0;
          this.obj.flag = false;
          chooseproject(this.obj).then((res) => {
            if (res.code == 1) {
              Notify({ type: 'success', message: res.msg });
              getproject(uid, reviewid, topic_id).then((res) => {
                if (res.data.list.length > 0) {
                  this.tableData = res.data.list;
                  const arrlength = this.checkArr(this.full_mark.label,this.tableData,this.fulltype);
                  const arrlength2 = this.checkArr(this.half_mark.label,this.tableData,this.halftype);
                  const num = Number(this.full_mark.total_num);
                  const num2 = Number(this.half_mark.total_num);
                  if (arrlength.length == num || arrlength.length > num) {
                    this.scorerecord.fullscore = 1;
                  } else if (arrlength2.length >= num2) {
                    this.scorerecord.fullscore = 0.5;
                  } else {
                    this.scorerecord.fullscore = 0;
                  }
                }
              });
              this.fzcss = ''
              this.hjcss = ''
              this.cscss = ''
              this.cxcss = ''
              this.xmcss = ''
            } else {
              Notify({ type: 'danger', message: res.msg });
            }
          });
          this.obj = {};
          this.fuzhus = false;
          this.hjs = false;
          this.css = false;
          this.cxs = false;
          this.xms = false;
        } else {
          this.xmcss = "";
        }
      } else {
        //下一题进行的提交
        const uid = this.nextuid;
        const reviewid = this.nextreviewid;
        const topic_id = this.questionid1;
        this.xms = true;
        if (this.xms === true) {
          this.xmcss = index;
          const myDate = new Date();
          const nowDate =
            myDate.getMonth() +
            1 +
            "/" +
            myDate.getDate() +
            "   " +
            myDate.getHours() +
            ":" +
            myDate.getMinutes();
          const numid = this.tableData.length + 1;
          this.obj.item_name = val.option_name;
          this.obj.item_id = val.option_id;
          this.obj.time = nowDate;
          this.obj.id = numid;
          // 以下是将用户所选项传给后台
          delete this.obj.time;
          this.obj.uid = uid;
          this.obj.review_id = reviewid;
          this.obj.topic_id = this.questionid1;
          this.obj.is_attach = 0;
          chooseproject(this.obj).then((res) => {
            if (res.code == 1) {
              Notify({ type: 'success', message: res.msg });
              getproject(uid, reviewid, topic_id).then((res) => {
                if (res.data.list.length > 0) {
                  this.tableData = res.data.list;
                  const arrlength = this.checkArr(this.full_mark.label,this.tableData,this.fulltype);
                  const arrlength2 = this.checkArr(this.half_mark.label,this.tableData,this.halftype);
                  const num = Number(this.full_mark.total_num);
                  const num2 = Number(this.half_mark.total_num);
                  if (arrlength.length == num || arrlength.length > num) {
                    this.scorerecord.fullscore = 1;
                  } else if (arrlength2.length >= num2) {
                    this.scorerecord.fullscore = 0.5;
                  } else {
                    this.scorerecord.fullscore = 0;
                  }
                }
              });
            } else {
              Notify({ type: 'danger', message: res.msg });
            }
          });
          this.obj = {};
          this.fzcss = "";
          this.hjcss = "";
          this.cscss = "";
          this.cxcss = "";
          this.xmcss = "";
          this.fuzhus = false;
          this.hjs = false;
          this.css = false;
          this.cxs = false;
          this.xms = false;
        } else {
          this.xmcss = "";
        }
      }
    },
    // 项目标签添加
    addproject() {
      const fuzhu = this.obj.hasOwnProperty("assist_name");
      const huanjing = this.obj.hasOwnProperty("envir_name");
      const place = this.obj.hasOwnProperty("place_name");
      const speech = this.obj.hasOwnProperty("noun_name");
      if (fuzhu == false && this.elect_assist === 1) {
        return Notify({ type: 'warning', message: "请选择辅助标签" });
      }
      if (huanjing == false && this.elect_envir === 1) {
        return Notify({ type: 'warning', message: "请选择环境标签" });
      }
      if (place == false && this.elect_place === 1) {
        return Notify({ type: 'warning', message: "请选择场所标签" });
      }
      if (speech == false && this.elect_noun === 1) {
        return Notify({ type: 'warning', message: "请选择词性标签" });
      }
      const pinguid = getuid();
      const reviewid = getreviewid();
      const myDate = new Date();
      const nowDate =
        myDate.getMonth() +
        1 +
        "/" +
        myDate.getDate() +
        "   " +
        myDate.getHours() +
        ":" +
        myDate.getMinutes();
      const numid = this.tableData.length + 1;
      this.obj.item_name = this.value;
      this.obj.time = nowDate;
      this.obj.id = numid;
      // 以下是将用户所选项传给后台
      delete this.obj.time;
      this.obj.uid = pinguid;
      this.obj.review_id = reviewid;
      this.obj.topic_id = this.questionid;
      this.obj.is_attach = 1;
      const data = {
        uid: pinguid,
        topic_id: this.questionid,
        name: this.value,
      };
      programadd(data).then((res) => {
        if (res.code === 1) {
          chooseproject(this.obj).then((res) => {
            if (res.code == 1) {
              getproject(pinguid, reviewid, this.questionid).then((res) => {
                if (res.data.list.length > 0) {
                  this.tableData = res.data.list;
                  const arrlength = this.checkArr(this.full_mark.label,this.tableData,this.fulltype);
                  const arrlength2 = this.checkArr(this.half_mark.label,this.tableData,this.halftype);
                  const num = Number(this.full_mark.total_num);
                  const num2 = Number(this.half_mark.total_num);
                  if (arrlength.length == num || arrlength.length > num) {
                    this.scorerecord.fullscore = 1;
                  } else if (arrlength2.length >= num2) {
                    this.scorerecord.fullscore = 0.5;
                  } else {
                    this.scorerecord.fullscore = 0;
                  }
                }
              });
              Notify({ type: 'success', message: res.msg });
            } else {
              Notify({ type: 'danger', message: res.msg });
            }
          });
          Notify({ type: 'success', message: "添加成功" });
          this.value = "";
        } else {
          Notify({ type: 'danger', message: res.msg });
        }
      });
    },
    complete(val) {
      let uid = getuid();
      let reviewid = getreviewid();
      let mainid = gettopicid();
      let lesson_id = getlesson_id();
      if (val == "add") {
        rightscore(uid, reviewid, mainid).then((res) => {
          this.allTopic = res.data;
          let lastId = this.allTopic[this.allTopic.length - 1].id;
          if (this.scorerecord.fullscore === "") {
            Dialog.alert({
              title: "提示",
              message: "请作答当前题目",
              confirmButtonColor: "#fff",
              messageAlign: "left",
              getContainer: ".body",
            }).then(() => {
              // on close
            });
            return false
          }
          if (this.scorerecord.fullscore === 0) {
            this.$dialog
              .confirm({
                title: "提示",
                message: `${this.name + this.mileage_name}未得分，即将结束本维度测评，是否确认？`,
                confirmButtonColor: "#fff",
                messageAlign: "left",
                getContainer: ".body",
              })
              .then(() => {
                let data = {
                  trecord_id: this.trecord_id,
                  toprecord_id: this.toprecord_id,
                  uid: uid,
                  review_id: reviewid,
                  main_id: mainid,
                  topic_id: this.questionid,
                  score: this.scorerecord.fullscore,
                  ans_record: JSON.stringify(this.setarr),
                };
                recordadd(data).then((res) => {
                  if (res.code == "1") {
                    this.nextMainConfirm(uid, reviewid, mainid, lesson_id);
                    Notify({ type: "success", message: res.msg });
                  }
                });
                // on confirm
              })
              .catch(() => {
                // on cancel
              });
            return false
          }
          if (this.questionid == lastId) {
            let data = {
              trecord_id: this.trecord_id,
              toprecord_id: this.toprecord_id,
              uid: uid,
              review_id: reviewid,
              main_id: mainid,
              topic_id: this.questionid,
              score: this.scorerecord.fullscore,
            };
            recordadd(data).then((res) => {
              if (res.code == "1") {
                this.nextMainConfirm(uid, reviewid, mainid, lesson_id);
                Notify({ type: 'success', message: res.msg });
              }
            });
          } else {
            let data = {
              trecord_id: this.trecord_id,
              toprecord_id: this.toprecord_id,
              uid: uid,
              review_id: reviewid,
              main_id: mainid,
              topic_id: this.questionid,
              score: this.scorerecord.fullscore,
            };
            recordadd(data).then((res) => {
              if (res.code == "1") {
                let params = {
                  uid: uid,
                  review_id: reviewid,
                  main_id: mainid,
                  topic_sort: this.topic_sort
                }
                nextTopic(params).then((res) => {
                  if (res.code == 9100) {
                    this.nextMainConfirm(uid, reviewid, mainid, lesson_id);
                    return
                  }
                  // return
                  if (res.data.topic_type == 1) {
                    this.$router.replace({
                      name: "addtable",
                      query: {
                        prosort: this.topic_sort,
                        ass_num: this.$route.query.ass_num,
                        eva_type: this.$route.query.eva_type,
                        is_rec: res.data.is_rec,
                        trec_id: res.data.trec_id,
                        topic_id: res.data.topic_id,
                        topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                      }
                    });
                    window.location.reload();
                  } else if (res.data.topic_type == 2) {
                    this.$router.replace({
                      name: "choosepicture",
                      query: {
                        prosort: this.topic_sort,
                        ass_num: this.$route.query.ass_num,
                        eva_type: this.$route.query.eva_type,
                        is_rec: res.data.is_rec,
                        trec_id: res.data.trec_id,
                        topic_id: res.data.topic_id,
                        topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                      }
                    });
                  } else if (res.data.topic_type == 3) {
                    this.$router.replace({
                      name: "addTruefalse",
                      query: {
                        prosort: this.topic_sort,
                        ass_num: this.$route.query.ass_num,
                        eva_type: this.$route.query.eva_type,
                        is_rec: res.data.is_rec,
                        trec_id: res.data.trec_id,
                        topic_id: res.data.topic_id,
                        topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                      }
                    });
                  }
                  getproject(uid, reviewid, this.questionid).then((res) => {
                    if (res.data.list.length > 0) {
                      this.tableData = res.data.list;
                    }
                  });
                });
                Notify({ type: 'success', message: res.msg });
              }
            });
          }
        });
      } else if (val == "jian") {
        let preTopicSort = this.topic_sort - 1;
        topicRecord(uid, preTopicSort, reviewid, mainid, 1).then((res) => {
          if (res.code != 1) {
            return Notify({ type: 'danger', message: res.msg });
          }
          if (res.data.topic_type === "1") {
            this.$router.replace({
              name: "addtable",
              query: {
                topic_sort: preTopicSort,
                type: 1,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type
              },
            });
            window.location.reload();
          } else if (res.data.topic_type === "2") {
            this.$router.replace({
              name: "choosepicture",
              query: {
                topic_sort: preTopicSort,
                type: 1,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type
              },
            });
          } else if (res.data.topic_type === "3") {
            this.$router.replace({
              name: "addTruefalse",
              query: {
                topic_sort: preTopicSort,
                type: 1,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type
              },
            });
          }
        });
      } else if (val == "finish") {
        if (this.scorerecord.fullscore == "") {
          this.scorerecord.fullscore = 0;
        }
        let data = {
          trecord_id: this.trecord_id,
          toprecord_id: this.toprecord_id,
          uid: uid,
          review_id: reviewid,
          main_id: mainid,
          topic_id: this.questionid,
          score: this.scorerecord.fullscore,
        };
        recordadd(data).then((res) => {
          if (res.code == "1") {
            this.nextMainConfirm(uid, reviewid, mainid, lesson_id);
            Notify({ type: 'success', message: res.msg });
          }
        });
      }
    },
    // 下一维度
    nextMainConfirm(uid, reviewid, mainid, lesson_id) {
      let data = {
        uid: uid,
        review_id: reviewid,
        main_id: mainid,
        lesson_id
      };
      finishAsses(data).then((res) => {
        if (res.code != 1) {
          return Notify({ type: 'danger', message: res.msg });
        } else {
          Notify({ type: 'success', message: res.msg });
        }
        if (this.nextname == "最后一个维度了") {
          if (this.isend) {
            Toast.success({
              duration: 3000,
              forbidClick: true,
              message: "恭喜你，完成评估",
              onClose: () => {
                this.$router.replace({
                  name: "startpingu",
                  query: {
                    ass_num: this.$route.query.ass_num,
                    eva_type: this.$route.query.eva_type
                  }
                }).then(() => {
                  this.$router.go(-1)
                });
              },
            });
          } else {
            Dialog.alert({
              title: "提示",
              message: "本维度已完成评估，请选择未完成的维度继续作答",
              confirmButtonColor: "#fff",
              messageAlign: "left",
              getContainer: ".body",
            }).then(() => {
              this.$router.replace({
                name: "startpingu",
                query: {
                  ass_num: this.$route.query.ass_num,
                  eva_type: this.$route.query.eva_type
                }
              }).then(() => {
                this.$router.go(-1)
              });
            });
          }
        } else {
          this.$dialog
            .confirm({
              title: "提示",
              message:
                "本维度的测评已结束，下一个测评维度是" +
                '<span style="color:red">' +
                this.nextname +
                "</span>",
              confirmButtonColor: "#fff",
              messageAlign: "left",
              confirmButtonText: "继续评估",
              cancelButtonText: "手动调整",
              getContainer: ".body",
            })
            .then(() => {
              settopicid(this.nextmainid);
              setltemname(this.nextname);
              setlesson_id(this.nextlessonid);
              let nextuid = getuid();
              let nextreviewid = getreviewid();
              let params = {
                uid: nextuid,
                review_id: nextreviewid,
                main_id: this.nextmainid,
                topic_sort: 0
              }
              nextTopic(params).then(
                (res) => {
                  if (res.data.topic_type == 1) {
                    this.$router.replace({
                      name: "addtable",
                      query: {
                        ass_num: this.$route.query.ass_num,
                        eva_type: this.$route.query.eva_type,
                        is_rec: res.data.is_rec,
                        trec_id: res.data.trec_id,
                        topic_id: res.data.topic_id,
                        topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                      }
                    });
                    window.location.reload();
                  } else if (res.data.topic_type == 2) {
                    this.$router.replace({
                      name: "choosepicture",
                      query: {
                        ass_num: this.$route.query.ass_num,
                        eva_type: this.$route.query.eva_type,
                        is_rec: res.data.is_rec,
                        trec_id: res.data.trec_id,
                        topic_id: res.data.topic_id,
                        topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                      }
                    });
                  } else if (res.data.topic_type == 3) {
                    this.$router.replace({
                      name: "addTruefalse",
                      query: {
                        ass_num: this.$route.query.ass_num,
                        eva_type: this.$route.query.eva_type,
                        is_rec: res.data.is_rec,
                        trec_id: res.data.trec_id,
                        topic_id: res.data.topic_id,
                        topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                      }
                    });
                  }
                }
              );
            })
            .catch(() => {
              this.$router.replace({
                name: "startpingu",
                query: {
                  ass_num: this.$route.query.ass_num,
                  eva_type: this.$route.query.eva_type
                }
              }).then(() => {
                this.$router.go(-1)
              });
            });
        }
      });
    },
    ona() {
      this.complete("jian");
    },
    scoreshow() {
      const uid = getuid();
      const reviewid = getreviewid();
      const mainid = gettopicid();
      this.show = true;
      rightscore(uid, reviewid, mainid).then((res) => {
        this.scorearr = res.data;
      });
    },
    manual(val) {
      this.auto = val;
      if (this.auto === "手动") {
        const coppy = JSON.parse(JSON.stringify(this.scorerecord.fullscore));
        this.scorerecord.fullcoppy = coppy;
        this.scorerecord.fullscore = "";
        this.zero = false;
        this.zerofive = false;
        this.one = false;
        this.text = "自动";
      } else {
        this.zero = false;
        this.zerofive = false;
        this.one = false;
        this.scorerecord.fullscore = this.scorerecord.fullcoppy;
        this.text = "手动";
      }
    },
    deltablemodel() {
      this.tableshow = false;
    },
    checkArr(arr1, arr2, type) {
      var rs = [];
      let keyArr = ['', 'item_id', 'envir_id', 'assist_id', 'place_id', 'noun_id']
      let typeKey = keyArr[type]
      for (var i = 0; i < arr1.length; i++) {
        for (var j = 0; j < arr2.length; j++) {
          if (arr1[i].id == arr2[j][typeKey]) {
            rs.push(arr2[j][typeKey]);
          }
        }
      }
      return rs;
    },
    opentable() {
      if (this.swithopen == false) {
        const uid = getuid();
        const reviewid = getreviewid();
        const topic_id = this.questionid;
        getproject(uid, reviewid, topic_id).then((res) => {
          if (res.data.list.length > 0) {
            this.tableData = res.data.list;
            this.tableshow = !this.tableshow;
            const arrlength = this.checkArr(this.full_mark.label,this.tableData,this.fulltype);
            const arrlength2 = this.checkArr(this.half_mark.label,this.tableData,this.halftype);
            const num = Number(this.full_mark.total_num);
            const num2 = Number(this.half_mark.total_num);
            if (arrlength.length == num || arrlength.length > num) {
              this.scorerecord.fullscore = 1;
            } else if (arrlength2.length >= num2) {
              this.scorerecord.fullscore = 0.5;
            } else {
              this.scorerecord.fullscore = 0;
            }
          } else {
            Notify({ type: 'warning', message: '请选择（标签类型名称）+标签' });
          }
        });
      } else {
        getproject(this.nextuid, this.nextreviewid, this.questionid1).then(
          (res) => {
            if (res.data.list.length > 0) {
              this.tableData = res.data.list;
              this.tableshow = !this.tableshow;
              const arrlength = this.checkArr(this.full_mark.label,this.tableData,this.fulltype);
              const arrlength2 = this.checkArr(this.half_mark.label,this.tableData,this.halftype);
              const num = Number(this.full_mark.total_num);
              const num2 = Number(this.half_mark.total_num);
              if (arrlength.length == num || arrlength.length > num) {
                this.scorerecord.fullscore = 1;
              } else if (arrlength2.length >= num2) {
                this.scorerecord.fullscore = 0.5;
              } else {
                this.scorerecord.fullscore = 0;
              }
            } else {
              Notify({ type: 'warning', message: '请选择（标签类型名称）+标签' });
            }
          }
        );
      }
    },
    deleteitem(val) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "删除项目记录后会影响本题得分，确定删除吗？",
          confirmButtonColor: "#fff",
          messageAlign: "left",
          getContainer: ".body",
        })
        .then(() => {
          if (this.swithopen === false) {
            const uid = getuid();
            const reviewid = getreviewid();
            const topic_id = this.questionid;
            const data = {
              id: val.id,
            };
            delchoose(data).then((res) => {
              if (res.code === 1) {
                getproject(uid, reviewid, topic_id).then((res) => {
                  this.tableData = res.data.list;
                  const arrlength = this.checkArr(this.full_mark.label,this.tableData,this.fulltype);
                  const arrlength2 = this.checkArr(this.half_mark.label,this.tableData,this.halftype);
                  const num = Number(this.full_mark.total_num);
                  const num2 = Number(this.half_mark.total_num);
                  if (arrlength.length == num || arrlength.length > num) {
                    this.scorerecord.fullscore = 1;
                  } else if (arrlength2.length >= num2) {
                    this.scorerecord.fullscore = 0.5;
                  } else {
                    this.scorerecord.fullscore = 0;
                  }
                });
              }
            });
          } else {
            const data = {
              id: val.id,
            };
            delchoose(data).then((res) => {
              if (res.code === 1) {
                getproject(this.nextuid,this.nextreviewid,this.questionid1).then((res) => {
                  this.tableData = res.data.list;
                  const arrlength = this.checkArr(this.full_mark.label,this.tableData,this.fulltype);
                  const arrlength2 = this.checkArr(this.half_mark.label,this.tableData,this.halftype);
                  const num = Number(this.full_mark.total_num);
                  const num2 = Number(this.half_mark.total_num);
                  if (arrlength.length == num || arrlength.length > num) {
                    this.scorerecord.fullscore = 1;
                  } else if (arrlength2.length >= num2) {
                    this.scorerecord.fullscore = 0.5;
                  } else {
                    this.scorerecord.fullscore = 0;
                  }
                });
              }
            });
          }
        })
        .catch(() => { });
    },
    scoreclick(val) {
      if (this.auto == "手动") {
        this.zero = false;
        this.zerofive = false;
        this.one = false;
        if (val === 0) {
          this.zero = true;
          this.zerofive = false;
          this.one = false;
          this.scorerecord.fullscore = 0;
        } else if (val === 0.5) {
          this.zerofive = true;
          this.zero = false;
          this.one = false;
          this.scorerecord.fullscore = 0.5;
        } else if (val === 1) {
          this.one = true;
          this.zero = false;
          this.one = false;
          this.scorerecord.fullscore = 1;
        }
      }
    },
    // 回看
    backSee(topic_sort, toprecord_id) {
      if (toprecord_id != 0) {
        let uid = getuid();
        let reviewid = getreviewid();
        let mainid = gettopicid();
        topicRecord(uid, topic_sort, reviewid, mainid, 0).then((res) => {
          if (res.code != 1) {
            return Notify({ type: 'danger', message: res.msg });
          }
          if (res.data.topic_type === "1") {
            this.$router.replace({
              name: "addtable",
              query: {
                topic_sort: topic_sort,
                type: 0,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type
              },
            });
            window.location.reload();
          } else if (res.data.topic_type === "2") {
            this.$router.replace({
              name: "choosepicture",
              query: {
                topic_sort: topic_sort,
                type: 0,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type
              },
            });
          } else if (res.data.topic_type === "3") {
            this.$router.replace({
              name: "addTruefalse",
              query: {
                topic_sort: topic_sort,
                type: 0,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type
              },
            });
          }
        });
      }
    },
    overlayTip() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "当前状态可查看，但无法编辑，点击确认即可返回维度列表",
          confirmButtonColor: "#fff",
          messageAlign: "left",
          getContainer: ".body",
        })
        .then(() => {
          this.$router.replace({
            name: "startpingu",
            query: {
              ass_num: this.$route.query.ass_num,
              eva_type: this.$route.query.eva_type
            }
          }).then(() => {
            this.$router.go(-1)
          });
        })
        .catch(() => {
        });
    },
    // 格式化表格消息内容
    stateFormat(row, column, cellValue) {
      if (!cellValue) return "";
      if (cellValue.length > 4) {
        //最长固定显示4个字符
        return cellValue.slice(0, 4) + "...";
      }
      return cellValue;
    }
  },
};
</script>

<style lang="less" scoped>
.body {
  min-height: 100vh;
  background: url(../src/img/bj.png) top left/100% auto no-repeat;
  padding-bottom: 0.7rem;
  button {
    border: none;
  }
  .van-nav-bar {
    background: none;
    &::after {
      border: none;
    }
    /deep/ .van-nav-bar__left {
      font-size: 0.14rem;
      .van-nav-bar__text {
        color: #666;
      }
      .van-icon {
        font-size: 0.14rem;
        color: #666;
        margin: 0;
      }
    }
    .btn {
      width: 1rem;
      height: 0.34rem;
      background: #f14947;
      border-radius: 0.06rem;
      font-size: 0.14rem;
      border: none;
    }
  }
  .from {
    padding: 0 16px;
  }
  .mycard {
    padding: 0.13rem 0.16rem;
    margin: 0 auto;
    font-size: 0.16rem;
    background: #ffffff;
    border-radius: 0.06rem;
    margin-top: 0.1rem;
    box-shadow: 0 0 0.1rem 0.01rem rgba(157, 4, 4, 0.05);
    box-sizing: border-box;
    .pingubtn {
      height: 0.34rem;
      background: #eea837;
      border-radius: 0.06rem;
      font-size: 0.16rem;
      overflow: hidden;
      float: left;
      margin-right: 0.16rem;
    }
    .text {
      color: #333333;
      line-height: 0.35rem;
      letter-spacing: 0.01rem;
    }
    .view-btns {
      display: flex;
      align-items: center;
      margin-top: 0.12rem;
      .btn {
        line-height: 0.26rem;
        background: #ffeeee;
        border-radius: 0.14rem;
        font-size: 0.13rem;
        color: #f14947;
        padding: 0 0.1rem;
        margin-right: 0.12rem;
        position: relative;
        &.active::after {
          content: "";
          display: block;
          width: 0.12rem;
          height: 0.12rem;
          background: #ffeeee;
          border-radius: 0.02rem;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 50%) rotate(45deg);
          z-index: 1;
        }
      }
    }
    .view-content {
      display: flex;
      flex-wrap: wrap;
      background: #f8f8f8;
      border-radius: 0.06rem;
      padding: 0.08rem 0.15rem;
      margin-top: 0.1rem;
      font-size: 0.13rem;
      color: #666;
      line-height: 1;
      .standard {
        margin: 0.05rem 0.3rem 0.05rem 0;
        display: flex;
        align-items: center;
        .score {
          display: inline-block;
          line-height: 0.2rem;
          background: #ffdb9f;
          border-radius: 0.1rem;
          padding: 0 0.07rem;
          font-size: 0.12rem;
          color: #674000;
          margin-right: 0.07rem;
        }
        span {
          vertical-align: sub;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .listproject {
    width: 95%;
    margin: 0.23rem auto 0.3rem;
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      p {
        width: 6%;
        height: 0.34rem;
        text-align: center;
        font-size: 0.16rem;
        color: #333333;
        margin: 0 0.1rem 0 0;
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        span {
          display: block;
          width: 0.33rem;
          height: 0.15rem;
          background: #ff7170;
          border-radius: 0.04rem;
          color: #fff;
          font-size: 0.1rem;
          line-height: 0.15rem;
          margin: 0 auto;
        }
      }
      ul {
        width: 94%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        li {
          padding: 0 0.13rem;
          height: 0.34rem;
          text-align: center;
          line-height: 0.34rem;
          border-radius: 0.06rem;
          border: 1px solid #cccccc;
          font-size: 0.14rem;
          color: #666666;
          margin-right: 0.1rem;
          background: #ffff;
          margin-bottom: 0.1rem;
        }
        li:last-child {
          margin-right: 0;
        }
        li.redfont {
          color: #f14947;
          border-color: #ffadac;
          background: #ffeeee;
        }
      }
    }
    .input {
      margin: 0.1rem 0 0 0;
      border-radius: 0.06rem;
      display: flex;
      box-sizing: border-box;
      font-size: 0;
      p {
        width: 6%;
        margin-right: 0.1rem;
      }
      .con {
        width: 45%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
      .van-cell {
        line-height: 0.16rem;
        border: 1px solid #efefef;
        border-radius: 0.06rem 0 0 0.06rem;
        border-right: none;
        overflow: hidden;
        padding: 0.09rem 0.16rem;
        font-size: 0.14rem;
        box-sizing: border-box;
      }
      .van-button--normal {
        width: 0.66rem;
        height: 0.37rem;
        background: #f14947;
        border-radius: 0 0.06rem 0.06rem 0;
        font-size: 0.14rem;
        color: #fff;
        border: none;
        padding: 0 0.15rem;
      }
      .van-cell-group {
        width: 2.56rem;
        height: 0.37rem;
        border: none;
      }
      [class*="van-hairline"]::after {
        border: none;
      }
    }
  }
  .shadowbox {
    width: 100%;
    height: 100vh;
    background: black;
    opacity: 0.5;
    z-index: 2;
    position: fixed;
    top: 0;
  }
  .tablemodel {
    width: 100%;
    background: #fff;
    position: fixed;
    bottom: 0.7rem;
    border-radius: 0.2rem 0.2rem 0px 0px;
    z-index: 98;
    overflow: hidden;
    padding: 0.2rem;
    box-sizing: border-box;
    /deep/tbody {
      overflow-y: scroll;
    }
    .i {
      width: 100%;
      height: 5px;
      background: #fff;
      position: absolute;
      bottom: -5px;
      z-index: 99;
    }
  }
  /deep/.el-table th.el-table__cell {
    background: #fff3f3;
    border-color: #fff3f3;
    font-size: 15px;
    color: #333;
    font-weight: 400;
  }
  .scorebtn {
    height: 0.44rem;
    position: fixed;
    right: 0;
    top: 70%;
    display: flex;
    .box {
      width: 0.44rem;
      height: 0.44rem;
      background: #ffecec;
      box-shadow: 0 0 0.06rem 0.01rem rgba(110, 8, 8, 0.23);
      &::before {
        position: absolute;
        top: 0.06rem;
        left: -0.17rem;
        content: "";
        width: 0.32rem;
        height: 0.32rem;
        background: #ffecec;
        transform: rotate(135deg);
        box-shadow: 0 0 0.06rem 0.01rem rgba(110, 8, 8, 0.23);
      }
      &::after {
        content: "分数";
        position: absolute;
        top: 0;
        left: 0;
        width: 0.44rem;
        height: 0.44rem;
        background: #ffecec;
        font-size: 0.15rem;
        color: #f14947;
        line-height: 0.44rem;
        text-align: center;
      }
    }
  }
  .scorebox {
    height: 100%;
    background: #ffffff;
    padding: 0.24rem 0.24rem 0.08rem;
    position: fixed;
    border-radius: 0.2rem 0.2rem 0 0;
    top: 0;
    right: 0;
    left: auto;
    transform: none;
  }
  .calculatescore {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0.16rem;
    p {
      color: #f14947;
      font-size: 0.16rem;
      margin: 0;
      width: 0.34rem;
      &.disabled {
        color: #999;
      }
    }
    ul {
      display: flex;
      li {
        width: 0.64rem;
        height: 0.34rem;
        background: #ffffff;
        border-radius: 0.17rem;
        border: 1px solid #cccccc;
        font-size: 0.15rem;
        color: #666666;
        text-align: center;
        line-height: 0.34rem;
        margin-left: 0.2rem;
      }
      li.active {
        background: #f1b207;
        color: #fff;
        border: 1px solid #f1b207;
      }
    }
  }
  .calculatescore:last-child {
    margin-bottom: 0.4rem;
  }
  .fixedcard {
    width: 100%;
    height: 0.7rem;
    background: #ffffff;
    box-shadow: 0 0 0.06rem 0.01rem rgba(157, 4, 4, 0.16);
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 55;
    box-sizing: border-box;
    padding: 0 0.2rem;
    .score {
      font-size: 0;
      span {
        display: inline-block;
        width: 0.56rem;
        height: 0.34rem;
        text-align: center;
        line-height: 0.34rem;
        background: #ffffff;
        border-radius: 0.17rem;
        border: 1px solid #cccccc;
        font-size: 0.15rem;
        color: #666666;
        margin-left: 0.1rem;
      }
      .active {
        background: #eea837;
        color: #fff;
        border-color: #eea837;
      }
      .active1 {
        background: #eea837;
        color: #fff;
        border-color: #eea837;
      }
    }
    .buttongroup {
      display: flex;
      div {
        width: 0.96rem;
        height: 0.34rem;
        border-radius: 0.17rem;
        opacity: 1;
        text-align: center;
        line-height: 0.34rem;
        font-size: 0.16rem;
        margin-left: 0.1rem;
      }
    }
    .item {
      flex-grow: 1;
      margin-left: 0.15rem;
      font-size: 0.16rem;
      span {
        margin-left: 0.13rem;
      }
      .myicon {
        color: #f14947;
        font-size: 0.16rem;
        margin-left: 0;
      }
    }
    .preTopic {
      height: fit-content;
      float: left;
      div {
        margin-left: 0;
      }
    }
  }
  /deep/ .van-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  /deep/ .van-dialog {
    width: 4.06rem;
    padding-bottom: 0.2rem;
    .van-dialog__header {
      text-align: left;
      padding: 0.2rem 0.24rem 0;
      color: #666;
    }
    .van-dialog__header::before {
      content: "!";
      display: inline-block;
      width: 0.18rem;
      height: 0.18rem;
      border-radius: 50%;
      text-align: center;
      font-size: 0.18rem;
      vertical-align: text-bottom;
      margin-right: 0.06rem;
      line-height: 0.2rem;
      padding: 0 0 0.01rem 0.01rem;
      background: #ffaa20;
      color: #fff;
    }
    .van-dialog__message--has-title {
      padding-top: 0.1rem;
      padding-bottom: 0.27rem;
      font-size: 0.14rem;
      line-height: 0.3rem;
    }
    .van-dialog__footer {
      justify-content: center;
      overflow: initial;
      .van-button {
        width: 0.94rem;
        height: 0.34rem;
        flex: none;
        border-radius: 0.06rem;
        margin: 0 0.13rem;
        font-size: 0.16rem;
      }
      .van-dialog__cancel {
        border: 1px solid #b2b2b2;
        color: #707070;
      }
      .van-dialog__confirm {
        background: #fa4b52;
        color: #fff;
      }
    }
    [class*="van-hairline"]::after {
      border: none;
    }
  }
}
@media screen and (max-width: 767px) {
  /deep/ .el-table__body,
  /deep/ .el-table__footer,
  /deep/ .el-table__header {
    width: 100% !important;
  }
  /deep/ colgroup col {
    width: auto;
  }
  /deep/ colgroup col:first-child {
    width: 0.8rem;
  }
  /deep/ colgroup col:last-child {
    width: 0.5rem;
  }
  /deep/ colgroup col:nth-last-child(2) {
    width: 1.5rem;
  }
  .body .van-nav-bar .btn {
    width: 1.2rem;
  }
  .body .fixedcard .score span {
    margin-left: 0.05rem;
  }
  .body .fixedcard .item {
    margin-left: 0.1rem;
  }
}
</style>
